import React, { useMemo, useEffect } from 'react';

import FeaturedListingsContext from 'app/contexts/FeaturedListingsContext';

import {
  useCreateViewImpressionsMutation,
  useCreateFeaturedListingClickMutation,
} from 'app/api/mainApi';

const useFeaturedListingsContext = ({ listingsData }) => {
  const [createViewImpressions] = useCreateViewImpressionsMutation();
  const [createFeaturedListingClick] = useCreateFeaturedListingClickMutation();

  useEffect(() => {
    if (listingsData?.listings) {
      createViewImpressions({ viewImpressionsToken: listingsData.viewImpressionsToken });
    }
  }, [listingsData]);

  const onListingClick = ({ event, indexInPage, listingId, callbackFn, context, listingIndex }) => {
    event.preventDefault();

    // 695 is the breakpoint for the layout,
    // above that it's 2,6, below that: 1,12
    // TBD if we need to send that to the server for click tracking
    // right now, we're hardcoded to {1,12}

    createFeaturedListingClick({
      listingId,
      context: context || listingsData.context,
      listingIndex: listingIndex || (listingsData.offset + indexInPage),
      listingLayout: '1,12',
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    }).finally(() => {
      if (callbackFn) { callbackFn(event); }
    });
  };

  // export the fully-built context for consumers of this hook to utilize
  const provider = ({ children }) => {
    const memOnListingClick = useMemo(() => ({ onListingClick }), [onListingClick]);

    return (
      <FeaturedListingsContext.Provider value={memOnListingClick}>
        {children}
      </FeaturedListingsContext.Provider>
    );
  };

  return {
    FeaturedListingsContextProvider: provider,
  };
};

export default useFeaturedListingsContext;
