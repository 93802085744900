import React, { useMemo } from 'react';
import { PropTypes } from 'prop-types';

import Carousel from 'react-material-ui-carousel';
import { Stack, Typography, Box, useMediaQuery } from '@mui/material';
import CircularProgress from 'app/components/shared/CircularProgress';

import { featureFlag } from 'app/util/featureFlag';
import accountApi, { useGetFeaturedListingsQuery } from 'app/api/accountApi';
import { useGetRecentGoodyBagsQuery } from 'app/api/assetApi';
import useFeaturedListingsContext from 'app/hooks/useFeaturedListingsContext';
import useListingDetailsAndFeedback from 'app/hooks/useListingDetailsAndFeedback';
import PhoneListingContainer from 'app/containers/PhoneListingContainer';
import GoodyBagCardContainer from 'app/containers/GoodyBagCardContainer';

// Simple chunk helper
function chunkArray(array, size) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

const SeekerForYou = ({ currentUser, availableBalance }) => {
  const { data, isFetching } = useGetFeaturedListingsQuery();
  const featuredListings = data?.listings;
  const { path, first_level_parent_id: firstLevelParentId } = data?.favorite_category || {};
  const { FeaturedListingsContextProvider } = useFeaturedListingsContext({ listingsData: data });

  const MemoizedFeaturedListingsContextProvider = useMemo(
    () => FeaturedListingsContextProvider,
    [data],
  );
  const { data: recentGoodyBagData } = useGetRecentGoodyBagsQuery(
    { category: firstLevelParentId },
    { skip: !firstLevelParentId },
  );

  const recentGoodyBags = recentGoodyBagData?.carousels?.[0]?.results;

  const {
    onListingDetailsClick,
    onListingFeedbackClick,
  } = useListingDetailsAndFeedback({
    listingDetailsFlagEnabled: featureFlag.enabled('LISTING_DETAILS_20955'),
  });
  const isSmallScreen = useMediaQuery('(max-width:950px)');
  const isLargeScreen = useMediaQuery('(max-width:1300px)');

  if (isFetching) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="50vh"
        width="100%"
      >
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading...
        </Typography>
      </Stack>
    );
  }

  let itemsPerSlide;
  if (isSmallScreen) {
    itemsPerSlide = 1;
  } else if (isLargeScreen) {
    itemsPerSlide = 2;
  } else {
    itemsPerSlide = 3;
  }

  // turning these into components before chunking allows us to set up pubnub listeners for each one, instead of just the visible ones
  const listingComponents = featuredListings?.map((listing) => (
    <PhoneListingContainer
      key={`listing_${listing.id}`}
      listing={listing}
      advisorLogin={false}
      onDetailsClick={onListingDetailsClick(listing)}
      onFeedbackClick={onListingFeedbackClick(listing)}
      currentUser={currentUser}
      api={accountApi}
      queryName="getFeaturedListings"
      queryDataKey="listings"
      queryArgs={undefined}
    />
  ));

  const listingSlides = chunkArray(listingComponents, itemsPerSlide);
  const goodySlides = recentGoodyBags && chunkArray(recentGoodyBags, itemsPerSlide);

  return (
    <>
      <Box sx={{ borderBottom: '1px solid #ccc', pb: 2 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" my={2} px={2.5}>
          <Typography variant="subtitle1">Featured Listings</Typography>
          <Typography variant="body2">
            <a href={`/categories/${path}`} style={{ textDecoration: 'none' }}>View All</a>
          </Typography>
        </Box>
        <MemoizedFeaturedListingsContextProvider>
          <Carousel
            navButtonsAlwaysVisible
            cycleNavigation={false}
            animation="slide"
            indicators={false}
            autoPlay={false}
            navButtonsProps={{ style: { zIndex: 1, opacity: 0.5, margin: 1 } }}
          >
            {listingSlides.map((group) => (
              <Box
                key={`carousel_${itemsPerSlide}_slide_${group[0].id}}`}
                sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}
              >
                {group.map((listingComponent, idx) => (
                  <Box
                    // eslint-disable-next-line react/no-array-index-key
                    key={`listing_slide_${idx}`}
                    sx={{
                      width: '330px',
                      flex: '0 0 330px',
                    }}
                  >
                    {listingComponent}
                  </Box>
                ))}
              </Box>
            ))}
          </Carousel>
        </MemoizedFeaturedListingsContextProvider>
      </Box>
      { recentGoodyBags && (
        <Box sx={{ mt: 2 }}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2} px={2.5}>
            <Typography variant="subtitle1">Recent Goodies</Typography>
            <Typography variant="body2">
              <a href={`/goody/search?category=${firstLevelParentId}&sort=4`} style={{ textDecoration: 'none' }}>View All</a>
            </Typography>
          </Box>
          <Carousel
            navButtonsAlwaysVisible
            cycleNavigation={false}
            animation="slide"
            indicators={false}
            autoPlay={false}
            navButtonsProps={{ style: { zIndex: 1, opacity: 0.5, margin: 1 } }}
          >
            {goodySlides.map((group) => (
              <Box
                key={`carousel_${itemsPerSlide}_slide_${group[0].id}}`}
                sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}
              >
                {group.map((goodyBag) => (
                  <Box
                    key={`goodyBag_${goodyBag.id}`}
                    sx={{
                      width: '330px',
                      flex: '0 0 330px',
                    }}
                  >
                    <GoodyBagCardContainer
                      goodyBag={goodyBag}
                      viewingSelf={false}
                      availableBalance={availableBalance}
                      currentUser={currentUser}
                      advisorLoading={false}
                      selected={false}
                      viewingSingleAdvisor={false}
                      titleIsLink
                    />
                  </Box>
                ))}
              </Box>
            ))}
          </Carousel>
        </Box>
      )}
    </>
  );
};

SeekerForYou.propTypes = {
  currentUser: PropTypes.object.isRequired,
  availableBalance: PropTypes.number.isRequired,
};

export default SeekerForYou;
